<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Booking
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings.currentPage * tableSettings.perPage -
              tableSettings.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              v-if="row.item.status === 'pending'"
              size="sm"
              class="mr-1"
              variant="success"
              @click="onConfirmAction(row.item, 'reserved')"
            >
              Reserve
            </b-button>

            <b-button
              v-if="row.item.status === 'reserved'"
              size="sm"
              class="mr-1"
              variant="success"
              @click="onConfirmAction(row.item, 'issued')"
            >
              Issue
            </b-button>

            <b-button
              v-if="row.item.status === 'issued'"
              size="sm"
              class="mr-1"
              variant="success"
              @click="onConfirmAction(row.item, 'returned')"
            >
              Return
            </b-button>

            <b-button
              size="sm"
              class="mr-1"
              @click="onViewDetails(row.item)"
            >
              View
            </b-button>

            <b-button
              v-if="
                row.item.status === 'pending' || row.item.status === 'reserved'
              "
              size="sm"
              class="mr-1"
              variant="danger"
              @click="onConfirmAction(row.item, 'cancelled')"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-details"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="View Details"
    >
      <form
        role="form"
        novalidate
      >
        <b-form-group>
          <label for="user">Requestor Name</label>
          <b-input
            name="user"
            :value="booking.user"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="email">Email</label>
          <b-input
            name="email"
            :value="booking.email"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="venue">Venue</label>
          <b-input
            name="venue"
            :value="booking.venue"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="date_from">Date From</label>
          <b-input
            name="date_from"
            :value="booking.date_from"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="date_to">Date To</label>
          <b-input
            name="date_to"
            :value="booking.date_to"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="purpose">Purpose</label>
          <b-textarea
            name="purpose"
            :value="booking.purpose"
            rows="6"
            max-rows="6"
            :disabled="true"
          />
        </b-form-group>
      </form>

      <span>Booked Item(s)</span>
      <b-table
        hover
        responsive
        class="mt-2"
        :items="booking.details"
        :fields="tableTwoSettings.fields"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-return"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="View Item(s)"
      @ok="onReturnItem"
    >
      <b-table
        hover
        responsive
        class="mt-2"
        :items="booking.details"
        :fields="tableThreeSettings.fields"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(action)="data">
          <div class="form-group">
            <b-form-checkbox
              v-model="data.item.checked"
              :name="`check-id-${data.item.id}`"
              size="lg"
              value="1"
              unchecked-value="0"
            />
          </div>
        </template>
      </b-table>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="!checkIfDisabledReturnButton"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-booking-form"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="New Booking"
      @ok="onConfirmPostAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="user"
            vid="user"
            rules="required"
          >
            <b-form-group>
              <label for="user">User</label>
              <v-select
                id="user"
                v-model="selectedUser"
                :options="users"
                :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                label="name"
                :disabled="state.busy || booking.details.length > 0"
              >
                <template #option="{ name, email }">
                  <strong>{{ name }}</strong><br>
                  <span>{{ email }}</span>
                </template>
                <template #no-options="">
                  No Available User
                </template>
              </v-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="venue"
            vid="venue"
            rules="required"
          >
            <b-form-group>
              <label for="venue">Venue</label>
              <b-form-select
                id="venue"
                v-model="booking.venue"
                :options="venues"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy || selectedUser === null || booking.details.length > 0"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Venue --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date from"
            vid="date_from"
            rules="required"
          >
            <b-form-group>
              <label for="date_from">Date From</label>
              <b-input
                id="date_from"
                v-model="booking.date_from"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date from"
                autocomplete="off"
                :disabled="
                  state.busy ||
                    booking.details.length > 0 ||
                    booking.venue === null
                "
                @change="onDateFromChange"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date to"
            vid="date_to"
            rules="required"
          >
            <b-form-group>
              <label for="date_to">Date To</label>
              <b-input
                id="date_to"
                v-model="booking.date_to"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date to"
                autocomplete="off"
                :disabled="
                  state.busy ||
                    booking.details.length > 0 ||
                    booking.venue === null
                "
                :min="minDateTo"
                @change="getItemList"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="purpose"
            vid="purpose"
            rules="required|max:500"
          >
            <b-form-group>
              <label for="purpose">Purpose</label>
              <b-textarea
                id="purpose"
                v-model="booking.purpose"
                :state="errors.length > 0 ? false : null"
                placeholder="enter purpose"
                autocomplete="off"
                rows="6"
                max-rows="6"
                :disabled="state.busy || booking.venue === null"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div>
            <ValidationProvider
              #default="{ errors }"
              name="item"
              vid="item"
              rules=""
            >
              <b-form-group>
                <label for="item">Available Item(s)</label>
                <v-select
                  id="item"
                  v-model="selectedItem"
                  :options="bookingItemFilteredLists"
                  label="name"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy || booking.venue === null"
                >
                  <template #first>
                    <v-select-option
                      :value="null"
                      disabled
                    >
                      -- Please Select Item --
                    </v-select-option>
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <b-button
              :disabled="allowAddItem || selectedItem === null"
              @click="onAddItem"
            >
              Add Item
            </b-button>
            <hr>
            <b-table
              hover
              responsive
              class="mt-2"
              :items="booking.details"
              :fields="tableFourSettings.fields"
              show-empty
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell()="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="onRemoveItem(row.item)"
                >
                  remove
                </b-button>
              </template>
            </b-table>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy || booking.details.length < 1"
          @click="ok()"
        >
          Submit
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AdminBookingService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminBookings',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Bookings'
    }
  },

  mixins: [formatter],

  data () {
    return {
      date_now: this.dateTimeLocalFormatter(Date.now()),
      state: {
        busy: false,
        editing: false
      },
      users: [],
      selectedUser: [],
      venues: [],
      selectedItem: [],
      booking: {
        id: 0,
        user: '',
        email: '',
        venue: '',
        date_from: '',
        date_to: '',
        details: []
      },
      items: [],
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          {
            key: 'created_at',
            label: 'booked at',
            formatter: this.dateTimeFormatter,
            sortKey: 'bookings.created_at',
            sortable: true
          },
          { key: 'status' },
          { key: 'user', label: 'requestor' },
          { key: 'email' },
          // { key: 'purpose' },
          { key: 'venue' },
          { key: 'date_from' },
          { key: 'date_to' }
        ]
      },
      tableTwoSettings: {
        fields: [
          'index',
          { key: 'item_name' },
          { key: 'date_from', formatter: this.dateTimeFormatter },
          { key: 'date_to', formatter: this.dateTimeFormatter }
        ]
      },
      tableThreeSettings: {
        fields: [
          'index',
          'action',
          { key: 'item_name' },
          { key: 'date_from', formatter: this.dateTimeFormatter },
          { key: 'date_to', formatter: this.dateTimeFormatter }
        ]
      },
      tableFourSettings: {
        fields: [
          'index',
          { key: 'item_name' },
          'actions'
        ]
      }
    }
  },

  computed: {
    checkIfDisabledReturnButton () {
      return (
        this.booking.details.filter(el => parseInt(el.checked) === 1).length >
        0
      )
    },

    allowAddItem () {
      const isOk = !!(
        this.selectedItem?.id !== null &&
        this.booking.date_from < this.booking.date_to
      )
      return !isOk
    },

    minDateTo () {
      return this.dateTimeLocalFormatter(this.booking.date_from)
    },

    bookingItemFilteredLists () {
      return this.items.filter(item => !this.booking.details.map(detail => detail.item_name).includes(item.name))
    }
  },

  mounted () {
    core.index()
    this.getVenueList()
    this.getUserList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminBookingService.get(
          `page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getVenueList () {
      await SharedListService.getVenueList().then(({ data }) => {
        data.forEach(item => {
          this.venues.push({
            value: item.id,
            text: item.name,
            label: item.name
          })
        })
      })
    },

    async getItemList () {
      this.selectedItem = null
      if (this.booking.date_from > this.booking.date_to) {
        this.$refs.form.setErrors({
          date_to: ['The date to must be a date after date from']
        })
      } else {
        this.state.busy = true
        await SharedListService.getItemList(`date_from=${this.booking.date_from}&date_to=${this.booking.date_to}`).then(({ data }) => {
          this.items = data
          this.state.busy = false
        }).catch(() => {
          this.state.busy = false
        })
      }
    },

    onDateFromChange () {
      if (this.booking.date_from >= this.booking.date_to) {
        this.booking.date_to = null
      } else {
        this.getItemList()
      }
    },

    async getUserList () {
      await SharedListService.getUserList().then(({ data }) => {
        this.users = data
      })
    },

    async onViewDetails (item) {
      this.booking.id = item.id
      this.booking.user = item.user
      this.booking.email = item.email
      this.booking.venue = item.venue
      this.booking.date_from = item.date_from
      this.booking.date_to = item.date_to
      this.booking.purpose = item.purpose
      this.booking.details = item.booking_details
      this.$bvModal.show('modal-view-details')
    },

    onReturnItem () {
      const item = {
        id: this.booking.id
      }
      const status = 'returned'

      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to return item(s)',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.onFormPutSubmit(item, status)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onConfirmAction (item, status) {
      let action = ''

      if (status === 'reserved') {
        action = 'reserved item(s)'
      }

      if (status === 'issued') {
        action = 'issue item(s)'
      }

      if (status === 'returned') {
        this.booking.id = item.id
        this.booking.details = item.booking_details
          .filter(el => el.status === 'issued')
          .map(el => {
            return { ...el, checked: 0 }
          })
        this.$bvModal.show('modal-view-return')
        return
      }

      if (status === 'cancelled') {
        action = 'cancel booking'
      }

      const title = `Do you really want to ${action}?`

      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: title,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.onFormPutSubmit(item, status)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onFormPutSubmit (item, status) {
      return new Promise(resolve => {
        this.state.busy = true

        const params = {
          id: item.id,
          status: status,
          booking_details:
            this.booking.details.length > 0
              ? this.booking.details.filter(el => parseInt(el.checked) === 1)
              : []
        }

        AdminBookingService.put(params)
          .then(({ data }) => {
            this.state.busy = false
            this.$swal
              .fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
              .then(() => {
                this.$refs.table.refresh()
              })
          })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onShowAddForm () {
      this.resetForm()
      this.$bvModal.show('modal-booking-form')
    },

    resetForm () {
      this.booking.venue = null
      this.booking.purpose = ''
      this.booking.date_from = this.dateTimeLocalFormatter(Date.now())
      this.booking.date_to = this.dateTimeLocalFormatter(Date.now())
      this.booking.details = []
      this.items = []
      this.selectedItem = null
      this.selectedUser = null
    },

    onAddItem () {
      if (this.selectedItem.item_id === null) {
        this.$bvModal.msgBoxOk('Oops! You have selected invalid item', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else if (this.booking.date_from >= this.booking.date_to) {
        this.$bvModal.msgBoxOk('Oops! Invalid booking date.', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else {
        const isExisting = this.booking.details?.filter(
          el => el.item_id === this.selectedItem.id
        )

        if (isExisting.length) {
          this.$bvModal.msgBoxOk(`Item ${this.selectedItem.name} already exists in your item list.`, {
            title: 'Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        } else {
          this.booking.details.push({
            item_id: this.selectedItem.id,
            item_name: this.selectedItem.name
          })
          this.selectedItem = null
        }
      }
    },

    onRemoveItem (item) {
      const oldItem = this.booking.details

      const newItem = oldItem.filter(el => {
        return el.item_name !== item.item_name
      })

      this.booking.details = newItem
    },

    async onConfirmPostAction (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.booking.details.length < 1) {
        return
      }

      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to create booking?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        this.booking.user = this.selectedUser?.id || null
        AdminBookingService.post(this.booking).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-booking-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                  title: 'Validation Error',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger',
                  centered: true
                })
                resolve(error)
              }
            }
          })
      })
    }
  }
}
</script>
